import { FaLocationDot } from "react-icons/fa6";
import { BiSolidBarChartSquare } from "react-icons/bi";
import { FaCartPlus } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { BiSolidReport } from "react-icons/bi";
import { FaUser } from "react-icons/fa";

export const MenuList = [
    {
        title: 'Dashboard',
        iconStyle: <BiSolidBarChartSquare size={22} color='gray' />,
        to: 'dashboard',
    },
    {
        title: 'Locker Pickup',
        iconStyle: <FaCartPlus size={22} color='gray' />,
        to: 'create-order',
    },
    {
        title: 'Reports',
        classsChange: 'mm-collapse',
        iconStyle: <BiSolidReport size={22} color='gray' />,
        content: [
            {
                title: 'Assigned Items',
                to: '/assigned-items',
            },
            {
                title: 'Stocked Items',
                to: '/stocked-items',
            },
            {
                title: 'Picked Up Items',
                to: '/pickedup-items',
            },
            {
                title: 'Return to stock',
                to: '/return-stock',
            },
            {
                title: 'Returns',
                to: '/returns',
            },
            {
                title: 'Transactions',
                to: '/transactions',
            },
            {
                title: 'Audit Trail',
                to: '/audit-trail',
            },
            {
                title: 'Message Log',
                to: '/message-log',
            },
        ],
    },
    {
        title: 'Manage Location',
        iconStyle: <FaLocationDot size={22} color='gray' />,
        to: 'location-list',
    },
    {
        title: 'Manage Accounts',
        iconStyle: <IoHome size={22} color='gray' />,
        to: 'account-list',
    },
    {
        title: 'Manage User',
        classsChange: 'mm-collapse',
        iconStyle: <FaUser size={22} color='gray' />,
        content: [
            {
                title: 'User',
                to: '/user-list',
            },
            {
                title: 'Role Permissions',
                to: '/roles',
            },
        ],
    },
]