/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from "react-toastify";
import request from './../../../services/AxiosInstance';
import { Button, Modal, Overlay } from "react-bootstrap";
import { Loader } from "../../components/bootstrap/Loader";
import { useNavigate } from "react-router-dom";
import ManageUser from './ManageUser';
import { AgGridReact } from 'ag-grid-react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import 'ag-grid-enterprise';
import moment from 'moment-timezone';

ModuleRegistry.registerModules([ServerSideRowModelModule]);
let TransactionFilterObj = {
    searchtext: "",
    pageNo: 0,
    pageSize: 5,
    sortOrder: "desc",
    sortColumn: "created_on",
    tableName: "",
};

const TransactionSortObj = {
    name: { tableName: 'users', sortColumn: 'name' },
    username: { tableName: 'users', sortColumn: 'username' },
    'contact.email': { tableName: 'contacts', sortColumn: 'email' },
    'contact.phone': { tableName: 'contacts', sortColumn: 'phone' },
    last_login_on: { tableName: 'users', sortColumn: 'last_login_on' },
    is_enabled: { tableName: 'users', sortColumn: 'is_enabled' },
};

const ManageUsersList = () => {
    const gridRef = useRef();
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [userRowData, setUserRowData] = useState('');
    const [loader, setLoader] = useState(false);
    const [type, setType] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [disableModal, setDisableModal] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [rowPerPage] = useState(5);

    const handleCsvfile = async (data) => {
        setLoader(true);
        await request('post', 'CSV/UserList', TransactionFilterObj).then(response => {
            try {
                return response;
            } catch (error) {
                return null;
            }
        })
            .then(csvData => {
                let csvRow = [];
                let Array = [['Name', 'UserName', 'AssocaitedAccount', 'Email', 'Phone', 'Roles', 'LastLogin', 'Status']];
                let result = csvData && csvData.Results && csvData.Results.length > 0 ? csvData.Results : [];
                if (result !== undefined) {
                    if (result && result.length > 0) {
                        for (let i of result) {
                            Array.push(
                                [
                                    i.name ? i.name : '',
                                    i.username ? i.username : '',
                                    i.parentAccount ? i.parentAccount.name : '',
                                    i.contact && i.contact.email ? i.contact.email : '',
                                    i.contact && i.contact.phone ? i.contact.phone : '',
                                    i.roles && i.roles.length > 0 && i.roles[0].name ? i.roles[0].name : '',
                                    i.last_login_on ? i.last_login_on : '',
                                    [true, 'true'].includes(i && i.is_enabled) ? 'Enabled' : 'Disabled',
                                ]
                            );
                        }

                        for (let i of Array) {
                            csvRow.push(i.join(","));
                        }
                        let csvString = csvRow.join("%0A");
                        let element = document.createElement("a");
                        element.href = 'data:attachment/csv.' + csvString;
                        element.target = "_Blank";
                        element.download = "AssignedItemsReport.csv";
                        document.body.appendChild(element);
                        element.click();
                    }
                }
                setLoader(false);
            });;
    }

    const handleUserDisable = async () => {
        const rowIdToDisable = userRowData && userRowData[0].id
        const disableUserObj = {
            id: rowIdToDisable,
            is_enabled: userRowData[0].is_enabled ? false : true
        };
        setLoader(true)
        const resDisable = await request('post', `userdis`, disableUserObj)
        if (resDisable.message) {
            toast.error(resDisable.message);
            setLoader(false);
            return
        }
        setDisableModal(false);
        setLoader(false);

        toast.success(userRowData[0].is_enabled ? "User disabled successfully" : "User enabled successfully")
    }

    const handleUserDelete = async () => {
        const rowIdToDelete = userRowData && userRowData[0].id
        setLoader(true)
        const resDelete = await request('post', `apis/i-ecom/user`, { rowIdToDelete })

        if (resDelete.message) {
            toast.error(resDelete.message);
            setLoader(false)
            return
        }
        const newData = userData.filter((x) => x.id !== rowIdToDelete);
        setUserData(newData)
        setDeleteModal(false)
        setLoader(false);
        toast.success("User deleted successfully")
    }

    const paginationPageSizeSelector = useMemo(() => [5, 10, 20], []);

    const onPaginationChanged = (params) => {
        const newPageSize = params.api.paginationGetPageSize();
        if (rowPerPage !== newPageSize) {
            TransactionFilterObj.pageSize = newPageSize;
        }
    };
    const gridOptions = {
        rowModelType: 'serverSide',
        gridBodyCls: 'ag-layout-auto-height'
    };

    const onGridReady = (params) => {
        const datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
    };

    const getServerSideDatasource = () => {
        return {
            getRows(params) {
                TransactionFilterObj.pageNo = params.api.paginationGetCurrentPage() + 1;
                const filterObject = params.request && params.request.filterModel
                const filterColumns = Object.keys(filterObject)

                if (filterObject) {
                    if (filterColumns.length === 0) {
                        TransactionFilterObj.searchObject = ''
                    } else {
                        const filters = {};
                        let apiObj = {};
                        Object.keys(filterObject).forEach(colId => {
                            const filter = filterObject[colId];
                            let adjustedColId = colId;

                            if (colId === 'contact.email') {
                                adjustedColId = 'email';
                                if (filter.filterType === 'text' && filter.type === 'searchtext') {
                                    filters[adjustedColId] = filter.filter;
                                }
                                apiObj = {
                                    email: filter
                                }
                            }
                            if (colId === 'contact.phone') {
                                adjustedColId = 'phone';
                                if (filter.filterType === 'text' && filter.type === 'searchtext') {
                                    filters[adjustedColId] = filter.filter;
                                }
                                apiObj = {
                                    phone: filter
                                }
                            }

                            if (colId === 'contact.email' || colId === 'contact.phone') {
                                TransactionFilterObj.searchObject = apiObj;
                            } else {
                                TransactionFilterObj.searchObject = filterObject;
                            }
                        });

                    }
                }

                if (params.request.sortModel.length > 0) {
                    TransactionFilterObj.tableName = TransactionSortObj[params.request.sortModel[0].colId].tableName
                    TransactionFilterObj.sortColumn = TransactionSortObj[params.request.sortModel[0].colId].sortColumn
                    TransactionFilterObj.sortOrder = params.request.sortModel[0].sort
                }

                apiCall();

                function apiCall() {
                    request('post', 'apis/i-ecom/handleorders/user/getusers', TransactionFilterObj)
                        .then(response => {
                            if (response && response.results) {
                                if (response.results.length > 0) {
                                    params.success({ rowData: response.results, rowCount: response.total });
                                    setUserData(response.results)
                                } else {
                                    params.success({ rowData: [], rowCount: 0 });
                                }
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            params.fail();
                        })
                }
            }
        };
    }

    const ImageCellRenderer = (props) => {
        return (
            <img
                src={props.value}
                alt=""
                style={{ width: '50px', height: '50px' }}
            />
        );
    };

    const ActionCellRenderer = ({ data }) => {
        const [show, setShow] = useState(false);
        const target = useRef(null);

        const handleView = () => {
            if (isView) {
                const accData = userData.filter((val) => val.id === data.id);
                if (accData.length > 0) {
                    setType('View');
                    setUserRowData(accData)
                } else {
                    toast.error('Data Not Found!')
                }
            }
        };

        const handleEdit = () => {
            if (isEdit) {
                const accData = userData.filter((val) => val.id === data.id)
                if (accData.length > 0) {
                    setType('Edit');
                    setUserRowData(accData)
                } else {
                    toast.error('Data Not Found!')
                }
            }
        };

        const handleDelete = () => {
            if (isDelete) {
                const _userData = userData.filter((val) => val.id === data.id)
                if (_userData.length > 0) {
                    setUserRowData(_userData);
                    setDeleteModal(true);
                } else {
                    toast.error('Data Not Found!')
                }
            }
        }

        const handleResetPassword = async () => {
            if (isResetPassword) {
                const _userData = userData.filter((val) => val.id === data.id)
                if (_userData.length > 0) {
                    setLoader(true);
                    const resetObj = {
                        email: _userData[0] && _userData[0].username
                    }
                    const resetRes = await request('post', 'adminUser-pswdReset', resetObj)
                    if (resetRes.message) {
                        toast.error(resetRes.message);
                        setLoader(false);
                        return
                    }
                    toast.success("Password reset successfully.")
                    setLoader(false)
                } else {
                    toast.error('Data Not Found!')
                }
            }
        }

        const handleDisable = () => {
            if (isDisable) {
                const _userData = userData.filter((val) => val.id === data.id)
                if (_userData.length > 0) {
                    setUserRowData(_userData);
                    setDisableModal(true);
                } else {
                    toast.error('Data Not Found!')
                }
            }
        }
        return (
            <>
                <Button variant="link" className='pr-0' ref={target} onClick={() => setShow(!show)}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                </Button>
                <Overlay target={target.current} show={show} placement="right">
                    {({ placement: _placement,
                        arrowProps: _arrowProps,
                        show: _show,
                        popper: _popper,
                        hasDoneInitialMeasure: _hasDoneInitialMeasure,
                        ...props }) => (
                        <div className='action-btn'
                            {...props}
                        >
                            <span
                                className={`action-link ${isView ? '' : 'cursor-na'}`}
                                onClick={() => handleView()}
                            >
                                View
                            </span>
                            <span
                                className={`action-link ${isEdit ? '' : 'cursor-na'}`}
                                onClick={() => handleEdit()}
                            >
                                Edit
                            </span>
                            <span
                                className={`action-link ${isDelete ? '' : 'cursor-na'}`}
                                onClick={() => handleDelete()}
                            >
                                Delete
                            </span>
                            <span
                                className={`action-link ${isDisable ? '' : 'cursor-na'}`}
                                onClick={() => handleDisable()}
                            >
                                {data.is_enabled ? "Disable" : "Enable"}
                            </span>
                            <span
                                className={`action-link ${isDisable ? '' : 'cursor-na'}`}
                                onClick={() => handleResetPassword()}
                            >
                                Reset Password
                            </span>
                        </div>
                    )}
                </Overlay>
            </>
        );
    }

    const DateCellFormatter = (params) => {
        if (params && params.data && params.data.last_login_on) {
            const strTimezone = sessionStorage.getItem('timeZone')
            const sessionTimeZone = strTimezone.split(') ')[1];
            const timeZone = ![undefined, 'undefined', null, 'null', ''] ? sessionTimeZone : 'America/Los_Angeles';
            const date = moment(new Date(params.data.last_login_on));
            return date.tz(timeZone).format('MM/DD/YYYY hh:mm:ss A');
        } else {
            return ''
        }
    }

    const TableHeader = [
        { headerName: 'Picture', field: "picture_url", sortable: false, filter: false, cellRenderer: ImageCellRenderer },
        { headerName: "Name", field: "name" },
        { headerName: "UserName", field: "username" },
        { headerName: "Associated Account", field: "parentAccount.name", sortable: false, filter: false },
        { headerName: "Preferred Location", field: "preferredLocation.selected_location_name", sortable: false, filter: false },
        { headerName: "Email", field: "contact.email" },
        { headerName: "Phone", field: "contact.phone" },
        {
            headerName: "Roles", field: "roles", filter: false, sortable: false, valueGetter: (params) => {
                const roles = params.data.roles || [];
                return roles.map(role => role.name).join(', ');
            }
        },
        {
            headerName: "LastLogin", field: "last_login_on",
            cellRenderer: DateCellFormatter,
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: [
                    {
                        displayKey: 'fromdate',
                        displayName: "FromDate",
                        predicate: (_, cellValue) => cellValue
                    },
                    {
                        displayKey: 'todate',
                        displayName: "ToDate",
                        predicate: (_, cellValue) => cellValue
                    },
                ],
                maxNumConditions: 2,
            },
        },
        { headerName: "Status", field: "is_enabled", filter: false, sortable: false, valueGetter: (params) => params.data.is_enabled === true || params.data.is_enabled === 'true' ? 'Enabled' : 'Disabled' },
        { headerName: "Actions", field: "Actions", filter: false, sortable: false, cellRenderer: ActionCellRenderer },
    ];

    const handlePermissions = () => {
        const permissions = JSON.parse(sessionStorage.getItem("permissions"));

        if (!permissions || permissions.length === 0) {
            setIsAdd(false);
            setIsView(false);
            setIsEdit(false);
            setIsDelete(false);
            setIsDisable(false);
            setIsResetPassword(false);
            return;
        }

        const manageBoxes = permissions.filter(item => item.permission_entity_type === "Manage Users");

        if (manageBoxes.length === 0) {
            setIsAdd(false);
            setIsView(false);
            setIsEdit(false);
            setIsDelete(false);
            setIsDisable(false);
            setIsResetPassword(false);
            return;
        }

        setIsAdd(manageBoxes.some(item => item.name.trim() === 'Add' && item.is_allowed === 1));
        setIsView(manageBoxes.some(item => item.name.trim() === 'View' && item.is_allowed === 1));
        setIsEdit(manageBoxes.some(item => item.name.trim() === 'Modify' && item.is_allowed === 1));
        setIsDelete(manageBoxes.some(item => item.name.trim() === 'Delete' && item.is_allowed === 1));
        setIsDisable(manageBoxes.some(item => item.name.trim() === 'Disabled' && item.is_allowed === 1));
        setIsResetPassword(manageBoxes.some(item => item.name.trim() === 'Reset Password' && item.is_allowed === 1));
    };


    useEffect(() => {
        handlePermissions();
    }, [])

    if (type === 'View' || type === 'Edit') {
        return (
            <>
                <ManageUser rowData={userRowData} type={type} setType={setType} />
            </>
        )
    }

    return (
        <>
            {loader ? <Loader /> :
                <div>
                    <div className="text-end mb-3">
                        <Button variant='tp-btn-light text-black fs-1 p-0 mr-2' className="tooltip-test text-primary" data-toggle="tooltip" title="Export CSV" onClick={handleCsvfile}><i className="fa-solid fa-file-csv"></i></Button>
                        <Button onClick={() => navigate('/user')} disabled={!isAdd} className="btn-sm btn-primary btn-rounded">
                            <i className="fa-solid fa-user-plus" />
                        </Button>
                    </div>
                    <div className="row">
                        <div id="myGrid" className={"ag-theme-alpine"} style={{ boxSizing: "border-box", width: "100%" }}>
                            <AgGridReact
                                ref={gridRef}
                                sideBar={false}
                                suppressMenuHide={true}
                                columnDefs={TableHeader}
                                defaultColDef={{
                                    flex: 1,
                                    minWidth: 150,
                                    floatingFilter: true,
                                    filter: 'agTextColumnFilter',
                                    filterParams: {
                                        filterOptions: [{
                                            displayKey: 'searchtext',
                                            displayName: 'Search Text',
                                            predicate: (cellValue) => cellValue
                                        }],
                                        maxNumConditions: 1,
                                    },
                                    resizable: true,
                                    sortable: true,
                                    menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                                }}
                                pagination={true}
                                paginationPageSize={20}
                                paginationPageSizeSelector={paginationPageSizeSelector}
                                cacheBlockSize={20}
                                maxBlocksInCache={0}
                                onGridReady={onGridReady}
                                gridOptions={gridOptions}
                                onPaginationChanged={onPaginationChanged}
                                frameworkComponents={{
                                    imageCellRenderer: ImageCellRenderer,
                                    actionCellRenderer: ActionCellRenderer,
                                }}
                            />
                        </div>
                        <Modal className="fade" show={deleteModal} centered>
                            <Modal.Header>
                                <Modal.Title>Are you sure you want to delete?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {userRowData && userRowData[0].name}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    className="btn-sm"
                                    variant="danger light"
                                    onClick={() => setDeleteModal(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="primary"
                                    onClick={() => handleUserDelete()}
                                >
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal className="fade" show={disableModal} centered>
                            <Modal.Header>
                                <Modal.Title>Are you sure you want to {userRowData && userRowData[0].is_enabled ? 'disable' : 'enable'}?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {userRowData && userRowData[0].name}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    className="btn-sm"
                                    variant="danger light"
                                    onClick={() => setDisableModal(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="primary"
                                    onClick={() => handleUserDisable()}
                                >
                                    {userRowData && userRowData[0].is_enabled ? 'Disable' : 'Enable'}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            }
        </>
    )
}

export default ManageUsersList