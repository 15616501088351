/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react'
import { Tab, Nav, Button } from 'react-bootstrap'
import Select from 'react-select'
import { useDropzone } from 'react-dropzone'
import request from './../../../services/AxiosInstance';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { Loader } from '../../components/bootstrap/Loader';
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { maskPhoneNumber } from '../../../helper/helper';

const ManageUser = ({ rowData, type, setType }) => {

    const reminderOpt = [
        { value: '5m', label: '5m' },
        { value: '10m', label: '10m' },
        { value: '15m', label: '15m' },
        { value: '30m', label: '30m' },
    ]
    const navigate = useNavigate();
    const [accountValues, setAccountValues] = useState([]);
    const [parentId, setParentId] = useState('');
    const [userId, setUserId] = useState('');
    const [boxList, setBoxList] = useState([])
    const [selectedBoxId, setSelectedBoxId] = useState('')
    const [rolesData, setRolesData] = useState([])
    const [loader, setLoader] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [badgeId, setBadgeId] = useState('');
    const [roleSelected, setRoleSelected] = useState([]);
    const [userImage, setUserImage] = useState('');
    const [workDayStart, setWorkDayStart] = useState('');
    const [workDayEnd, setWorkDayEnd] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const [reminderTime, setReminderTime] = useState('');
    const [sunday, setSunday] = useState(false);
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [isSMS, setIsSMS] = useState(false);
    const [isPush, setIsPush] = useState(false);
    const [errors, setErrors] = useState({});

    const handleClear = () => {
        setAccountValues([]);
        setFirstName('');
        setLastName('');
        setEmailId('');
        setPhoneNumber('');
        setBadgeId('');
        setSelectedBoxId([]);
        setRoleSelected([])

    }

    const handleCalenderReset = () => {
        setWorkDayStart('');
        setWorkDayEnd('');
        setIsDefault(false);
        setReminderTime('');
        setSunday(false);
        setMonday(false);
        setTuesday(false);
        setWednesday(false);
        setThursday(false);
        setFriday(false);
        setSaturday(false);
        setIsEmail(false);
        setIsSMS(false);
        setIsPush(false);
    }

    const getLocationsByAccount = async () => {
        const parentAccountId = sessionStorage.getItem('parentAccountId');
        if (![undefined, undefined, null, null, ''].includes(parentAccountId)) {
            const resLocation = await request('get', `getBoxesByStoreId/${parentAccountId}`)
            if (resLocation.message) {
                toast.error(resLocation.message);
                return
            }
            const boxes = resLocation.map((x) => ({ value: x.id, label: x.name, locationtype_id: x.location_type_id }));
            setBoxList(boxes);
            setSelectedBoxId(boxes[0]);
            if (rowData && rowData.length > 0) {
                let [data] = rowData
                const _location = boxes.find((opt) => opt.value === data.preferredLocation.selected_location_id)
                setSelectedBoxId(_location);
            }
        }
    }

    const getAccount = async () => {
        setLoader(true)
        const accResData = await request('get', 'accounts');
        if (accResData.message) {
            setLoader(false)
            toast.error(accResData.message)
            return;
        }
        const accountValues = accResData.map(item => ({ value: item.id, label: item.name }));
        setAccountValues(accountValues);
        setParentId(accountValues[0]);
        if (rowData && rowData.length > 0) {
            let [data] = rowData
            const type = accountValues.find((opt) => opt.value === data.parent_account_id);
            setParentId(type);
        }

        const resRoles = await request('get', 'roles')
        if (resRoles.message) {
            toast.error(resRoles.message);
            return;
        }
        const rolesData = resRoles.map(item => ({ key: item.id, label: item.name }))
        setRolesData(rolesData);
        setLoader(false);
    }

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        const fileWithPreview = Object.assign(file, {
            preview: URL.createObjectURL(file)
        });
        setUserImage(fileWithPreview)
    }, [])

    const handleRemoveImage = () => setUserImage(null)
    const { getRootProps } = useDropzone({ onDrop })

    const handleOnStoreChange = (store) => setRoleSelected(store)

    const handleScheduleCalendar = async () => {
        setLoader(true)
        const saveScheduleCalendar = {
            workDayStart: workDayStart,
            workDayEnd: workDayEnd,
            reminder: isDefault === true ? '5m' : reminderTime && reminderTime.value,
            isDefaultReminder: isDefault,
            isSunday: sunday,
            isMonday: monday,
            isTuesday: tuesday,
            isWednesday: wednesday,
            isThursday: thursday,
            isFriday: friday,
            isSaturday: saturday,
            isEmail: isEmail,
            isSMS: isSMS,
            isPush: isPush,
            userId: userId,
            accountId: parentId.value,
        };

        const resCalender = await request('post', 'schedule/user-schedule-calender', saveScheduleCalendar);
        if (resCalender.message) {
            toast.error(resCalender.message)
            setLoader(false);
            return
        }
        if (resCalender && resCalender.status === 200) {
            if (resCalender._response && resCalender._response.createSchedule) {
                setType('')
                handleCalenderReset();
                toast.success("Schedule calendar created Successfully!!")
                setLoader(false)
            } else {
                setType('')
                handleCalenderReset();
                toast.success("Schedule calendar updated Successfully!!")
                setLoader(false)
            }
        } else if (resCalender && resCalender.status === 500) {
            toast.error(resCalender.res.error)
            setLoader(false)
            return
        }
    };

    const handleSubmit = async () => {
        setLoader(true)
        let data = new FormData();
        data.append('file', userImage);
        data.append('parent_account_id', parentId && parentId.value);
        data.append('first_name', firstName);
        data.append('last_name', lastName);
        data.append('badge_id', badgeId);
        data.append('email', emailId);
        data.append('phone', phoneNumber);
        data.append('selected_location_id', selectedBoxId.value);
        data.append('selected_location_type_id', selectedBoxId.locationtype_id);
        data.append('selected_location_name', selectedBoxId.label);
        data.append('roles', roleSelected);

        if (!['View', 'Edit'].includes(type)) {
            const result = fetch(process.env.REACT_APP_BASEURL + 'users', {
                method: 'POST',
                headers: {
                    Authorization:
                        'Bearer ' +
                        JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
                            .jwtToken,
                },
                body: data,
            });
            result
                .then((result) => {
                    result.json().then((res) => {
                        setLoader(false)
                        if (res.error || res.message) {
                            if (res.success === false) {
                                toast.error(res.message);
                                return
                            } else {
                                toast.error("Contact Administrator");
                                return;
                            }
                        } else {
                            handleClear();
                            toast.success("User Added");
                            navigate('/user-list')
                        }
                    });
                })
                .catch((error) => {
                    setLoader(false)
                    toast.error(error.message)
                });
        } else {
            data.append('id', userId)
            const result = fetch(process.env.REACT_APP_BASEURL + 'users', {
                method: 'PUT',
                withCredentials: true,
                headers: {
                    Authorization:
                        'Bearer ' +
                        JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
                            .jwtToken,
                },
                body: data,
            });
            result
                .then((result) => {
                    result.json().then((res) => {
                        setLoader(false)
                        if (res.error) {
                            toast.error(res.error.message)
                            return;
                        } else {
                            setType('');
                            handleClear();
                            toast.success("User Updated !!");
                            navigate('/user-list')
                        }
                    });
                })
                .catch((error) => {
                    setLoader(false)
                    toast.error(error.message)
                });
        }
    }

    const handlePhone = (e) => {
        const regex = /^[0-9\W]*$/;
        if (regex.test(e.target.value)) {
            setErrors({})
            setPhoneNumber(e.target.value);
        } else {
            setErrors({ phone: "Enter valid mobile number" })
        }
    }

    const handlePhoneBlur = (e) => {
        let phoneNumber = e.target.value;
        phoneNumber = phoneNumber.replace(/\D/g, '');
        if (phoneNumber.length === 10) {
            phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
            setPhoneNumber(phoneNumber)
        } else {
            setErrors({ phone: "Enter valid mobile number" })
        }
    }


    useEffect(() => {
        getLocationsByAccount();
        getAccount();
        if (rowData && rowData.length > 0) {
            let [data] = rowData;
            setUserImage(data && data.parentAccount && data.parentAccount.picture_url)
            setFirstName(data.first_name);
            setLastName(data.last_name);
            setEmailId(data.contact && data.contact.email);
            setPhoneNumber(data.contact && maskPhoneNumber(data.contact.phone));
            setBadgeId(data.badge_id);
            setUserImage(data.picture_url);
            setUserId(data.id)

            if (data.roles) {
                const selectedRoleIds = data.roles.map(role => role.id);
                setRoleSelected(selectedRoleIds)
            }

            if (data.scheduleCalendar) {
                let sDate = new Date(data.scheduleCalendar.work_day_starts);
                let eDate = new Date(data.scheduleCalendar.work_day_ends);
                setWorkDayStart(sDate);
                setWorkDayEnd(eDate);
                setIsDefault(data.scheduleCalendar.is_default_reminder);
                let remind = data.scheduleCalendar.reminder;
                setReminderTime({ value: remind, label: remind });
                setSunday(data.scheduleCalendar.is_sunday);
                setMonday(data.scheduleCalendar.is_monday);
                setTuesday(data.scheduleCalendar.is_tuesday);
                setWednesday(data.scheduleCalendar.is_wednesday);
                setThursday(data.scheduleCalendar.is_thursday);
                setFriday(data.scheduleCalendar.is_friday);
                setSaturday(data.scheduleCalendar.is_saturday);
                setIsEmail(data.scheduleCalendar.is_email);
                setIsSMS(data.scheduleCalendar.is_sms);
                setIsPush(data.scheduleCalendar.is_push);
            }
        }
    }, [])

    return (
        <div className='position-relative'>
            {loader ? <div className="loader">
                <Loader />
            </div> : ""}
            <Tab.Container defaultActiveKey='USERDETAILS'>
                <Nav as='ul' className='nav-tabs'>
                    <Nav.Item as='li' >
                        <Nav.Link eventKey={'USERDETAILS'}>
                            USER DETAILS
                        </Nav.Link>
                    </Nav.Item>
                    {type === 'Edit' || type === 'View' ?
                        <Nav.Item as='li' >
                            <Nav.Link eventKey={'CALENDAR'}>
                                CALENDAR
                            </Nav.Link>
                        </Nav.Item> : ""}
                </Nav>

                <Tab.Content className='pt-4'>
                    {/* USER DETAILS */}
                    <Tab.Pane eventKey='USERDETAILS'>
                        <div className='row'>
                            <div className='col-xl-12 col-lg-12'>
                                <h4 className="my-3 fw-bold">
                                    {type === 'View' ? 'User' :
                                        type === 'Edit' ? 'Edit User' : 'User'}
                                </h4>
                            </div>
                            <div className='col-xl-10 col-lg-10'>
                                <div className='row'>
                                    <div className='col-xl-4 col-lg-4'>
                                        <div className="form-group mb-3">
                                            <label>Parent Account</label>
                                            <Select
                                                value={parentId}
                                                options={accountValues}
                                                onChange={(e) => setParentId(e)}
                                                style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4'>
                                        <div className="form-group mb-3">
                                            <label>First Name</label>
                                            <input
                                                type="text"
                                                value={firstName}
                                                disabled={type === 'View'}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                className="form-control  form-control-sm fs-16" />
                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4'>
                                        <div className="form-group mb-3">
                                            <label>Last Name</label>
                                            <input
                                                type="text"
                                                value={lastName}
                                                disabled={type === 'View'}
                                                onChange={(e) => setLastName(e.target.value)}
                                                className="form-control  form-control-sm fs-16" />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Email Address</label>
                                            <input
                                                type="text"
                                                value={emailId}
                                                disabled={type === 'View'}
                                                onChange={(e) => setEmailId(e.target.value)}
                                                className="form-control  form-control-sm fs-16" />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Phone Number</label>
                                            <input
                                                type="text"
                                                value={phoneNumber}
                                                disabled={type === 'View'}
                                                onChange={(e) => handlePhone(e)}
                                                onBlur={(e) => handlePhoneBlur(e)}
                                                className="form-control  form-control-sm fs-16"
                                            />
                                            {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="form-group mb-3">
                                            <label>Badge ID</label>
                                            <input
                                                type="text"
                                                value={badgeId}
                                                disabled={type === 'View'}
                                                onChange={(e) => setBadgeId(e.target.value)}
                                                className="form-control  form-control-sm fs-16" />
                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4'>
                                        <label>Preferred Location</label>
                                        <Select
                                            value={selectedBoxId}
                                            options={boxList}
                                            onChange={(val) => setSelectedBoxId(val)}
                                            style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                    <div id="multiselect" className='col-xl-12 col-lg-12'>
                                        <label>Roles</label>
                                        {rolesData && rolesData.length &&
                                            <DropdownMultiselect
                                                selected={roleSelected}
                                                buttonClass='drop-multi-sm btn-light'
                                                options={rolesData}
                                                palceholder="Select Roles"
                                                name="roles"
                                                handleOnChange={handleOnStoreChange}
                                                disabled={type === 'View'}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <div id='avatarImg' {...getRootProps()}>
                                        {userImage ? (
                                            <div className='bx-shd'>
                                                <img className="rounded-3" src={userImage.preview ? URL.createObjectURL(userImage) : userImage} height='120px' width='120px' alt="AccountImage" />
                                            </div>
                                        ) : (<div className='bx-shd'> <svg focusable="false" aria-hidden="true" width="100px" height="100px" viewBox="0 0 24 24" data-testid="CloudUploadIcon">
                                            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3" fill="green" />
                                        </svg>
                                        </div>)}
                                    </div>
                                    <label className='pt-1'>Upload Image</label>
                                    <Button variant='info tp-btn btn-sm w-50' onClick={() => handleRemoveImage()}>REMOVE IMAGE</Button>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-5'>
                            <div className='col-12 text-end'>
                                <Button
                                    onClick={() => ['View', 'Edit'].includes(type) ? setType('') : navigate('/user-list')}
                                    className='me-2 btn-sm'
                                >
                                    Cancel
                                </Button>
                                {type !== 'View' ?
                                    <Button
                                        onClick={() => handleSubmit()}
                                        className='me-2 btn-sm'
                                        disabled={type === 'View'}
                                    >
                                        {type === 'Edit' ? "Update" : "Add"}
                                    </Button> : ""}
                            </div>
                        </div>
                    </Tab.Pane>

                    {/* CALENDAR */}
                    <Tab.Pane eventKey='CALENDAR'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6'>
                                <div className='row'>
                                    <h3 className="my-3 fw-bold">Work Schedule</h3>
                                </div>
                                <div className='row'>
                                    <label>Work day starts</label>
                                    <div className="input-group mb-3">
                                        <DatePicker
                                            selected={workDayStart}
                                            disabled={type === 'View'}
                                            maxDate={new Date()}
                                            onChange={(val) => setWorkDayStart(val)}
                                            wrapperClassName="w-90"
                                            className="form-control form-control-sm fs-16 input-group-right" />
                                        <i className='fa fa-calendar input-group-text input-group-right-icon'></i>
                                    </div>
                                    <label>Work day end</label>
                                    <div className="input-group mb-3">
                                        <DatePicker
                                            selected={workDayEnd}
                                            disabled={type === 'View'}
                                            maxDate={new Date()}
                                            onChange={(val) => setWorkDayEnd(val)}
                                            wrapperClassName="w-90"
                                            className="form-control form-control-sm fs-16 input-group-right" />
                                        <i className='fa fa-calendar input-group-text input-group-right-icon'></i>
                                    </div>
                                </div>
                                <div className='row'>
                                    <h3 className="my-3 fw-bold">Calender Options</h3>
                                    <div>
                                        <input
                                            type='checkbox'
                                            checked={isDefault}
                                            disabled={type === 'View'}
                                            onChange={(e) => setIsDefault(e.target.checked)}
                                        />
                                        <label className='ml-2'>Default reminder</label>
                                    </div>
                                    <div>
                                        <label>Reminder</label>
                                        <Select
                                            value={reminderTime}
                                            options={reminderOpt}
                                            isDisabled={type === 'View'}
                                            onChange={(val) => setReminderTime(val)}
                                            style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div>
                                    <h3 className="my-3 fw-bold">Work Week</h3>
                                    <div className="row mb-2 flex flex-row">
                                        <div className="col-2">
                                            <input
                                                type="checkbox"
                                                checked={sunday}
                                                disabled={type === 'View'}
                                                onChange={(e) => setSunday(e.target.checked)}
                                                className="form-check-input m-0"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <h4>Sunday</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-2 flex flex-row">
                                        <div className="col-2">
                                            <input
                                                type="checkbox"
                                                checked={monday}
                                                disabled={type === 'View'}
                                                onChange={(e) => setMonday(e.target.checked)}
                                                className="form-check-input m-0"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <h4>Monday</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-2 flex flex-row">
                                        <div className="col-2">
                                            <input
                                                type="checkbox"
                                                checked={tuesday}
                                                disabled={type === 'View'}
                                                onChange={(e) => setTuesday(e.target.checked)}
                                                className="form-check-input m-0"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <h4>Tuesday</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-2 flex flex-row">
                                        <div className="col-2">
                                            <input
                                                type="checkbox"
                                                checked={wednesday}
                                                disabled={type === 'View'}
                                                onChange={(e) => setWednesday(e.target.checked)}
                                                className="form-check-input m-0"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <h4>Wednesday</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-2 flex flex-row">
                                        <div className="col-2">
                                            <input
                                                type="checkbox"
                                                checked={thursday}
                                                disabled={type === 'View'}
                                                onChange={(e) => setThursday(e.target.checked)}
                                                className="form-check-input m-0"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <h4>Thursday</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-2 flex flex-row">
                                        <div className="col-2">
                                            <input
                                                type="checkbox"
                                                checked={friday}
                                                disabled={type === 'View'}
                                                onChange={(e) => setFriday(e.target.checked)}
                                                className="form-check-input m-0"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <h4>Friday</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-2 flex flex-row">
                                        <div className="col-2">
                                            <input
                                                type="checkbox"
                                                checked={saturday}
                                                disabled={type === 'View'}
                                                onChange={(e) => setSaturday(e.target.checked)}
                                                className="form-check-input m-0"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <h4>Saturday</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div>
                                    <h3 className="my-3 fw-bold">Notification</h3>
                                    <div className="form-group">
                                        <label className="toggle-switch p-0">
                                            <input
                                                type="checkbox"
                                                checked={[true, 'true'].includes(isEmail) ? true : false}
                                                disabled={type === 'View'}
                                                onChange={(e) => setIsEmail(e.target.checked)}
                                                className='form-check-input mx-3 mt-0'
                                            />
                                            <span className="form-check-label">Email</span>
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label className="toggle-switch p-0">
                                            <input
                                                type="checkbox"
                                                checked={[true, 'true'].includes(isSMS) ? true : false}
                                                disabled={type === 'View'}
                                                onChange={(e) => setIsSMS(e.target.checked)}
                                                className='form-check-input mx-3 mt-0'
                                            />
                                            <span className="form-check-label">SMS</span>
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label className="toggle-switch p-0">
                                            <input
                                                type="checkbox"
                                                checked={[true, 'true'].includes(isPush) ? true : false}
                                                disabled={type === 'View'}
                                                onChange={(e) => setIsPush(e.target.checked)}
                                                className='form-check-input mx-3 mt-0'
                                            />
                                            <span className="form-check-label">Push</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-5'>
                            <div className='col-12 text-end'>
                                <Button
                                    onClick={() => ['View', 'Edit'].includes(type) ? setType('') : navigate('/user-list')}
                                    className='me-2 btn-sm'
                                >
                                    Cancel
                                </Button>
                                {type !== 'View' ?
                                    <Button
                                        onClick={() => handleScheduleCalendar()}
                                        className='me-2 btn-sm'
                                    >
                                        Update Calendar
                                    </Button> : ""}
                            </div>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default ManageUser