import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import BOX_STATUS from "../../../../constant/BoxStatus";
import request from "../../../../services/AxiosInstance";
import CurdTypes from "../../../../constant/CurdTypes";
import EventTypes from "../../../../constant/EventTypes";
import RetailLabelTemplate from "../PrintTemplate";
import { useReactToPrint } from "react-to-print";
import { Loader } from "../../../components/bootstrap/Loader";
import Select from 'react-select'

const PlaceOrder = ({
  selectedBin,
  parentaccountId,
  handleEventLog,
  getBoxes,
  pharmacyDetails,
  boxDetails,
  accountDetails,
  setGoSteps,
  setShowWizard,
  handleInvoiceCreate,
  productOpt
}) => {
  const [loader, setLoader] = useState("");
  const [products, setProducts] = useState([{}]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [printModal, setPrintModal] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    console.log(JSON.stringify(selectedBin, null, 2));
  }, [selectedBin]);

  const handleAddProduct = () => {
    setProducts([...products, {}]);
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);

    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts.splice(index, 1);
    setSelectedProducts(updatedSelectedProducts);
  };

  const handleProductChange = (value, index) => {
    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts[index] = value;
    setSelectedProducts(updatedSelectedProducts);
  };

  const handleSubmit = async (type) => {
    if (selectedProducts.length <= 0) {
      toast.error("No product selected");
      return
    }
    const transformedData = selectedProducts.map(item => ({
      id: item.id,
      item: item.label,
      desc: item.desc
    }));
    let orderdata = {};
    orderdata["request_no"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.request_no
      : selectedBin?.request_no;
    orderdata["first_name"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.first_name
      : selectedBin?.first_name;
    orderdata["last_name"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.last_name
      : selectedBin?.last_name;
    orderdata["email"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.email
      : selectedBin?.email;
    orderdata["bin_id"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.bin_id
      : selectedBin?.bin_id;
    orderdata["pickup_code"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.pickup_code
      : selectedBin?.pickup_code;
    orderdata["stock_code"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.stock_code
      : selectedBin?.stock_code;
    orderdata["box_id"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.box_id
      : selectedBin?.box_id;
    orderdata["type_id"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.type_id
      : selectedBin?.type_id;
    orderdata["items"] = transformedData;
    orderdata["box_position"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.box_position
      : selectedBin?.box_position
    orderdata["customer_id"] = Array.isArray(selectedBin)
      ? selectedBin[0]?.icustomer_id
      : selectedBin?.icustomer_id
    console.log(JSON.stringify(orderdata, null, 2));
    setLoader(true);
    if (orderdata !== null) {
      const resOrder = await request(
        "post",
        "apis/i-ecom/handleorders",
        orderdata
      );
      if (resOrder.message) {
        setLoader(false);
        toast.error("Something went wrong.");
        return;
      }
      toast.success("Order is created Successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      const orderIds = resOrder && resOrder.map((item) => item.id);
      const user = JSON.parse(sessionStorage.getItem("user"));
      const user_id = user.username;
      let descriptions = `Order is assigned with stock code ${selectedBin[0]?.stock_code} and pick up ${selectedBin[0]?.pickup_code}`;
      const event_params = {
        event_name: CurdTypes.UPDATE,
        event_type_name: EventTypes.ASSIGNED,
        description: descriptions,
        type_id: BOX_STATUS.ASSIGNED,
        parent_account_id: parentaccountId,
        user_id: user_id,
        order_id: orderIds,
        box_id: selectedBin[0]?.box_id,
      };
      if (type === 0) {
        setPrintModal(true);
        setTimeout(() => {
          handleInvoiceCreate()
          handlePrint();
          setPrintModal(false);
          setShowWizard(false);
        }, 1500);
      } else {
        setPrintModal(false);
        setShowWizard(false);
      }
      handleEventLog(event_params);
      getBoxes();
    }
    setLoader(false);
  };

  return (
    <div className="col-12">
      {loader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div>
        {products.map((_, index) => (
          <div className="row" key={index}>
            <div className="col-12 d-flex">
              <div className="mt-4">
                {index > 0 ? (
                  <Button
                    className="fs-24 btn-icon"
                    variant="outlined"
                    onClick={() => handleRemoveProduct(index)}
                  >
                    <i className="fa-solid fa-circle-minus" />
                  </Button>
                ) : (
                  <Button
                    className="fs-24 btn-icon"
                    variant="outlined"
                    onClick={handleAddProduct}
                  >
                    <i className="fa-solid fa-circle-plus" />
                  </Button>
                )}
              </div>
              <div className="d-flex">
                <div style={{ width: "170px" }}>
                  <label className="pt-3">Products</label>
                  <Select
                    value={selectedProducts[index] || ''}
                    onChange={(val) => handleProductChange(val, index)}
                    options={productOpt}
                    autoComplete="off"
                    style={{
                      lineHeight: '40px',
                      color: '#7e7e7e',
                      paddingLeft: '15px',
                    }}
                  />
                </div>
                <div style={{ width: "250px", overflow: 'clip' }} className="ms-3 mt-4">
                  {selectedProducts[index] && (
                    <p>{selectedProducts[index].desc}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div
          className="col-xl-12 col-xxl-12 mt-4"
          style={{ textAlign: "right" }}
        >
          <Button
            className="m-2 btn-sm btn-secondary sw-btn-prev me-1"
            onClick={() => setGoSteps(1)}
          >
            Prev
          </Button>
          <Button
            variant="primary"
            className="m-2 btn-sm"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="m-2 btn-sm"
            onClick={() => handleSubmit(0)}
          >
            Save & Print
          </Button>
        </div>
      </div>
      <Modal className="fade" show={printModal} centered>
        <Modal.Body>
          <RetailLabelTemplate
            boxId={selectedBin[0]?.box_id}
            ref={componentRef}
            accountDetails={accountDetails}
            pharmacyDetails={pharmacyDetails}
            boxDetails={boxDetails}
            connectionType={0}
            requestNo={selectedBin[0]?.request_no}
            productDetail={selectedProducts}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PlaceOrder;
