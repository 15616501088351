/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import request from './../../../services/AxiosInstance';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment-timezone';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import 'ag-grid-enterprise';
import { GrPowerReset } from 'react-icons/gr';
import { maskPhoneNumber } from '../../../helper/helper';
import { toast } from 'react-toastify';
import { Loader } from '../../components/bootstrap/Loader';
import AccountType from '../../../constant/AccountType';

ModuleRegistry.registerModules([ServerSideRowModelModule]);
let TransactionFilterObj = {
    box_ids: [],
    fromdate: "",
    searchObject: "",
    pageNo: 0,
    pageSize: 20,
    sortOrder: "desc",
    sortColumn: "pickuped_date",
    store_ids: [],
    tableName: "",
    todate: "",
};

const TransactionSortObjRetail = {
    profile_id: { tableName: 'icustomers', sortColumn: 'profile_id' },
    mobile_no: { tableName: 'icustomers', sortColumn: 'mobile_no' },
    invoice_id: { tableName: 'orders', sortColumn: 'invoice_id' },
    item_name: { tableName: 'products', sortColumn: 'name' },
    stock_code: { tableName: 'orders', sortColumn: 'stock_code' },
    bin_id: { tableName: 'orders', sortColumn: 'bin_id' },
    pickup_code: { tableName: 'orders', sortColumn: 'pickup_code' },
    store: { tableName: 'accounts', sortColumn: 'name' },
    box: { tableName: 'boxes', sortColumn: 'name' },
    pickuped_date: { tableName: 'orders', sortColumn: 'pickuped_date' },
    stocked_date: { tableName: 'orders', sortColumn: 'stocked_date' },
};

const TransactionSortObjBuildDeploy = {
    email: { tableName: 'icustomers', sortColumn: 'email' },
    first_name: { tableName: 'icustomers', sortColumn: 'first_name' },
    last_name: { tableName: 'icustomers', sortColumn: 'last_name' },
    product_name: { tableName: 'ecom_product', sortColumn: 'name' },
    product_id: { tableName: 'ecom_product', sortColumn: 'product_id' },
    request_no: { tableName: 'orders', sortColumn: 'request_no' },
    stock_code: { tableName: 'orders', sortColumn: 'stock_code' },
    bin_id: { tableName: 'orders', sortColumn: 'bin_id' },
    pickup_code: { tableName: 'orders', sortColumn: 'pickup_code' },
    store: { tableName: 'accounts', sortColumn: 'name' },
    box: { tableName: 'boxes', sortColumn: 'name' },
    pickuped_date: { tableName: 'orders', sortColumn: 'pickuped_date' },
    stocked_date: { tableName: 'orders', sortColumn: 'stocked_date' },
}

const PickUpItems = () => {
    const gridRef = useRef();
    const [storeData, setStoreData] = useState([]);
    const [location, setLocation] = useState([]);
    const [rowPerPage] = useState(5);
    const [loader, setLoader] = useState(false);
    const [isBuild, setIsBuild] = useState(false);

    const getAccount = async () => {
        const account = await request('get', 'accounts');
        const storeId = account.map(item => item.id);
        const obj = { store_ids: storeId };
        const storeData = account.map(item => ({ id: item.id, name: item.name }));
        setStoreData(storeData);
        const getBoxes = await request('post', 'getBoxesByStoreIds', obj);
        const location = getBoxes.map(item => ({ id: item.id, name: item.name }));
        setLocation(location);
    };

    const paginationPageSizeSelector = useMemo(() => [5, 10, 20], []);

    const onPaginationChanged = (params) => {
        const newPageSize = params.api.paginationGetPageSize();
        if (rowPerPage !== newPageSize) {
            TransactionFilterObj.pageSize = newPageSize;
        }
    };
    const gridOptions = {
        rowModelType: 'serverSide',
        gridBodyCls: 'ag-layout-auto-height',
    };

    const onGridReady = (params) => {
        const datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
        params.api.sizeColumnsToFit();
    };

    const getServerSideDatasource = () => {
        return {
            getRows(params) {
                TransactionFilterObj.pageNo = params.api.paginationGetCurrentPage() + 1;
                const filterObject = params.request && params.request.filterModel
                const filterColumns = Object.keys(filterObject)

                // to set box_ids
                if (filterObject.box) {
                    const filterValues = filterObject.box.values;
                    if (filterValues.length > 0) {
                        TransactionFilterObj.box_ids = location.filter(boxes => {
                            return filterValues.includes(boxes.name)
                        }).map(box => box.id)
                    }
                } else {
                    TransactionFilterObj.box_ids = []
                }

                // to set store_ids
                if (filterObject.store) {
                    const filterValues = filterObject.store.values;
                    TransactionFilterObj.store_ids = storeData.filter(accounts => {
                        return filterValues.includes(accounts.name)
                    }).map(store => store.id)
                } else {
                    TransactionFilterObj.store_ids = []
                }

                // to set fromdate and todate
                if (filterObject.pickuped_date) {
                    const dateValue = filterObject.pickuped_date;
                    if (dateValue.operator) {
                        // for date range
                        if (dateValue.condition1.type === 'fromdate' && dateValue.condition2.type === 'todate') {
                            const date1 = moment(dateValue.condition1.dateFrom).format('YYYY-MM-DD')
                            const date2 = moment(dateValue.condition2.dateFrom).format('YYYY-MM-DD')
                            TransactionFilterObj.fromdate = date1
                            TransactionFilterObj.todate = date2
                        }
                        if (dateValue.condition2.type === 'fromdate' && dateValue.condition1.type === 'todate') {
                            const date2 = moment(dateValue.condition1.dateFrom).format('YYYY-MM-DD')
                            const date1 = moment(dateValue.condition2.dateFrom).format('YYYY-MM-DD')
                            TransactionFilterObj.fromdate = date1
                            TransactionFilterObj.todate = date2
                        }
                    } else {
                        // for from/to date
                        const date = moment(dateValue.dateFrom).format('YYYY-MM-DD')
                        if (dateValue.type === 'fromdate') {
                            TransactionFilterObj.fromdate = date
                            TransactionFilterObj.todate = ''
                        } else {
                            TransactionFilterObj.todate = date
                            TransactionFilterObj.fromdate = ''
                        }
                    }
                }

                // to set fromdate and todate
                if (filterObject.stocked_date) {
                    const dateValue = filterObject.stocked_date;
                    if (dateValue.operator) {
                        // for date range
                        if (dateValue.condition1.type === 'fromdate' && dateValue.condition2.type === 'todate') {
                            const date1 = moment(dateValue.condition1.dateFrom).format('YYYY-MM-DD')
                            const date2 = moment(dateValue.condition2.dateFrom).format('YYYY-MM-DD')
                            TransactionFilterObj.fromdate = date1
                            TransactionFilterObj.todate = date2
                        }
                        if (dateValue.condition2.type === 'fromdate' && dateValue.condition1.type === 'todate') {
                            const date2 = moment(dateValue.condition1.dateFrom).format('YYYY-MM-DD')
                            const date1 = moment(dateValue.condition2.dateFrom).format('YYYY-MM-DD')
                            TransactionFilterObj.fromdate = date1
                            TransactionFilterObj.todate = date2
                        }
                    } else {
                        // for from/to date
                        const date = moment(dateValue.dateFrom).format('YYYY-MM-DD')
                        if (dateValue.type === 'fromdate') {
                            TransactionFilterObj.fromdate = date
                            TransactionFilterObj.todate = ''
                        } else {
                            TransactionFilterObj.todate = date
                            TransactionFilterObj.fromdate = ''
                        }
                    }
                }

                if (filterObject) {
                    if (filterColumns.length === 0) {
                        TransactionFilterObj.searchObject = ''
                    } else {
                        TransactionFilterObj.searchObject = filterObject
                    }
                }

                if (params.request.sortModel.length > 0) {
                    TransactionFilterObj.tableName = isBuild ? TransactionSortObjBuildDeploy[params.request.sortModel[0].colId].tableName : TransactionSortObjRetail[params.request.sortModel[0].colId].tableName
                    TransactionFilterObj.sortColumn = isBuild ? TransactionSortObjBuildDeploy[params.request.sortModel[0].colId].sortColumn : TransactionSortObjRetail[params.request.sortModel[0].colId].sortColumn
                    TransactionFilterObj.sortOrder = params.request.sortModel[0].sort
                }
                const isStoreNotSelected = Boolean(filterObject.store && filterObject.store.values.length === 0)
                const isBoxNotSelected = Boolean(filterObject.box && filterObject.box.values.length === 0)

                if (isStoreNotSelected === true || isBoxNotSelected === true) {
                    // store or box is not selected, so empty data
                    params.success({ rowData: [], rowCount: 0 });

                } else { // store or box is selected
                    apiCall()
                }
                function apiCall() {
                    request('post', 'apis/i-ecom/handleorders/order/getPickedupitems', TransactionFilterObj)
                        .then(response => {
                            if (response && response.results) {
                                if (response.results.length > 0) {
                                    params.success({ rowData: response.results, rowCount: response.total });
                                } else {
                                    params.success({ rowData: [], rowCount: 0 });
                                }
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            params.fail();
                        })
                }
            }
        };
    }

    const DateCellFormatter = (params) => {
        if (params && params.data && params.data.pickuped_date) {
            const strTimezone = sessionStorage.getItem('timeZone')
            const sessionTimeZone = strTimezone.split(') ')[1];
            const timeZone = ![undefined, 'undefined', null, 'null', ''] ? sessionTimeZone : 'America/Los_Angeles';
            const date = moment(new Date(params.data.pickuped_date));
            return date.tz(timeZone).format('MM/DD/YYYY hh:mm:ss A');
        } else {
            return ''
        }
    }

    const StockDateCellFormatter = (params) => {
        if (params && params.data && params.data.stocked_date) {
            const strTimezone = sessionStorage.getItem('timeZone')
            const sessionTimeZone = strTimezone.split(') ')[1];
            const timeZone = ![undefined, 'undefined', null, 'null', ''] ? sessionTimeZone : 'America/Los_Angeles';
            const date = moment(new Date(params.data.stocked_date));
            return date.tz(timeZone).format('MM/DD/YYYY hh:mm:ss A');
        } else {
            return ''
        }
    }

    const TableHeaderRetail = [
        {
            headerName: "PickUp Date", field: "pickuped_date",
            cellRenderer: DateCellFormatter,
            filter: 'agDateColumnFilter',
            width: 200,
            filterParams: {
                filterOptions: [
                    {
                        displayKey: 'fromdate',
                        displayName: "FromDate",
                        predicate: (_, cellValue) => cellValue
                    },
                    {
                        displayKey: 'todate',
                        displayName: "ToDate",
                        predicate: (_, cellValue) => cellValue
                    },
                ],
                maxNumConditions: 2,
            },
        },
        {
            headerName: "Stocked Date", field: "stocked_date",
            cellRenderer: StockDateCellFormatter,
            filter: 'agDateColumnFilter',
            width: 200,
            filterParams: {
                filterOptions: [
                    {
                        displayKey: 'fromdate',
                        displayName: "FromDate",
                        predicate: (_, cellValue) => cellValue
                    },
                    {
                        displayKey: 'todate',
                        displayName: "ToDate",
                        predicate: (_, cellValue) => cellValue
                    },
                ],
                maxNumConditions: 2,
            },
        },
        { headerName: 'Profile', field: "profile_id" },
        {
            headerName: "Mobile",
            field: "mobile_no",
            valueFormatter: params => maskPhoneNumber(params.data.mobile_no) || params.data.mobile_no,
        },
        { headerName: "Invoice", field: "invoice_id" },
        { headerName: "Items", field: "item_name" },
        { headerName: "BIN", field: "bin_id" },
        { headerName: "Stock Code", field: "stock_code" },
        { headerName: "P/U Code", field: "pickup_code" },
        {
            headerName: "Store", field: "store", filter: true,
            filterParams: {
                values: storeData.map(account => {
                    return account.name
                })
            },
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        },
        {
            headerName: "Location", field: "box", filter: true,
            filterParams: {
                values: location.map(box => {
                    return box.name
                })
            },
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        },
    ];

    const TableHeaderBuildDeploy = [
        {
            headerName: "PickUp Date", field: "pickuped_date",
            cellRenderer: DateCellFormatter,
            filter: 'agDateColumnFilter',
            width: 200,
            filterParams: {
                filterOptions: [
                    {
                        displayKey: 'fromdate',
                        displayName: "FromDate",
                        predicate: (_, cellValue) => cellValue
                    },
                    {
                        displayKey: 'todate',
                        displayName: "ToDate",
                        predicate: (_, cellValue) => cellValue
                    },
                ],
                maxNumConditions: 2,
            },
        },
        {
            headerName: "Stocked Date", field: "stocked_date",
            cellRenderer: StockDateCellFormatter,
            filter: 'agDateColumnFilter',
            width: 200,
            filterParams: {
                filterOptions: [
                    {
                        displayKey: 'fromdate',
                        displayName: "FromDate",
                        predicate: (_, cellValue) => cellValue
                    },
                    {
                        displayKey: 'todate',
                        displayName: "ToDate",
                        predicate: (_, cellValue) => cellValue
                    },
                ],
                maxNumConditions: 2,
            },
        },
        { headerName: 'Request No', field: "request_no" },
        { headerName: "Email", field: "email" },
        { headerName: "First Name", field: "first_name" },
        { headerName: "Last Name", field: "last_name" },
        { headerName: "Product Name", field: "product_name" },
        { headerName: "Product Id", field: "product_id" },
        { headerName: "BIN", field: "bin_id" },
        { headerName: "Stock Code", field: "stock_code" },
        { headerName: "P/U Code", field: "pickup_code" },
        {
            headerName: "Store", field: "store", filter: true,
            filterParams: {
                values: storeData.map(account => {
                    return account.name
                })
            },
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        },
        {
            headerName: "Location", field: "box", filter: true,
            filterParams: {
                values: location.map(box => {
                    return box.name
                })
            },
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        },
    ];

    const handleFilterReset = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setFilterModel(null);
            gridRef.current.api.onFilterChanged();
        }
    };

    const fetchData = async () => {
        const totalRows = gridRef.current.api.paginationGetRowCount();
        TransactionFilterObj.pageSize = totalRows
        const response = await request('post', 'apis/i-ecom/handleorders/order/getPickedupitems', TransactionFilterObj);
        if (!response || !response.results) {
            toast.error('Error while fetching data for CSV')
            setLoader(false)
            return []
        }
        return response.results;
    };

    const onExportAllData = async () => {
        setLoader(true)
        const allData = await fetchData();

        if (allData.length > 0) {
            const columnDefs = gridRef.current.api.getColumnDefs();

            const csvData = allData.map(row => {
                let rowData = {};
                columnDefs.forEach(colDef => {
                    const field = colDef.field;
                    if (field) {
                        rowData[field] = row[field] ?? '';
                    }
                });
                return rowData;
            });

            let csvContent = 'data:text/csv;charset=utf-8,';
            const headers = columnDefs.map(colDef => colDef.headerName).join(',');
            csvContent += `${headers}\n`;

            csvData.forEach(row => {
                const rowString = columnDefs.map(colDef => {
                    const field = colDef.field;
                    if (field) {
                        return `"${row[field]}"`;
                    }
                    return '';
                }).join(',');
                csvContent += `${rowString}\n`;
            });

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'Picked_up_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoader(false)
        } else {
            setLoader(false)
            toast.error("Error while creating CSV")
        }
    };

    const getContextMenuItems = () => {
        return [
            'copy',
            'copyWithHeaders',
            {
                name: 'Export All Data',
                action: onExportAllData,
            },
        ];
    };

    useEffect(() => {
        getAccount();
        const accountType = sessionStorage.getItem('type_id')
        if (accountType === AccountType.Build_Deploy) {
            setIsBuild(true)
        } else {
            setIsBuild(false)
        }
    }, [])

    return (
        <div>
            {loader ? <div className="loader">
                <Loader />
            </div> : ""}
            <div className='text-end fs-26 mb-3'>
                <span className='mx-4' onClick={() => handleFilterReset()}>
                    <GrPowerReset size={26} color='gray' />
                </span>
            </div>
            <div id="myGrid" className={"ag-theme-quartz"} style={{ boxSizing: "border-box", width: "100%" }}>

                <AgGridReact
                    ref={gridRef}
                    sideBar={false}
                    suppressMenuHide={true}
                    columnDefs={isBuild ? TableHeaderBuildDeploy : TableHeaderRetail}
                    defaultColDef={{
                        minWidth: 100,
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            filterOptions: [{
                                displayKey: 'searchtext',
                                displayName: 'Search Text',
                                predicate: (cellValue) => cellValue
                            }],
                            maxNumConditions: 1,
                        },
                        resizable: true,
                        sortable: true,
                        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                    }}
                    pagination={true}
                    paginationPageSize={20}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    cacheBlockSize={20}
                    maxBlocksInCache={0}
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    onPaginationChanged={onPaginationChanged}
                    getContextMenuItems={getContextMenuItems}
                />
            </div>
        </div>
    )
}

export default PickUpItems