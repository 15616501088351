import { Col, Container, Row } from "react-bootstrap";

const VerifyOrder = ({ selectedBin }) => {
  return (
    <Container className="p-0">
      {Array.isArray(selectedBin) ? (
        <>
          <Row>
            <Col md={7}>
              <Row>
                <Col md={4}>
                  <strong>Email:</strong>
                </Col>
                <Col md={8}>{selectedBin[0]?.email}</Col>
              </Row>
              <Row>
                <Col md={4}>
                  <strong>Request No:</strong>
                </Col>
                <Col md={8}>{selectedBin[0]?.request_no}</Col>
              </Row>
              <Row>
                <Col md={4}>
                  <strong>Door:</strong>
                </Col>
                <Col md={8}>{selectedBin[0]?.bin_id}</Col>
              </Row>
              <Row>
                <Col md={4}>
                  <strong>Stock Code:</strong>
                </Col>
                <Col md={8}>{selectedBin[0]?.stock_code}</Col>
              </Row>
              <Row>
                <Col md={4}>
                  <strong>Pick-Up Code:</strong>
                </Col>
                <Col md={8}>{selectedBin[0]?.pickup_code}</Col>
              </Row>
            </Col>
            <Col md={5}>
              {selectedBin.map((x) => (
                <>
                  <Row className="mb-1">
                    <Col md={2}>
                      <strong>Item:</strong>
                    </Col>
                    <Col md={10}>{x?.name}</Col>
                  </Row>
                </>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="mb-4">
            <Col sm={1}>
              <strong>Profile:</strong>
            </Col>
            <Col sm={2}>{selectedBin?.profile_id}</Col>
            <Col sm={1}>
              <strong>Invoice:</strong>
            </Col>
            <Col sm={2}>{selectedBin?.invoice_id}</Col>
            <Col sm={1}>
              <strong>Item:</strong>
            </Col>
            <Col sm={2}>{selectedBin?.name}</Col>
            <Col sm={1}>
              <strong>Door:</strong>
            </Col>
            <Col sm={2}>{selectedBin?.bin_id}</Col>
          </Row>
          <Row className="mb-4">
            <Col sm={1}>
              <strong>Mobile:</strong>
            </Col>
            <Col sm={2}>{selectedBin?.mobile_no}</Col>
            <Col sm={2}>
              <strong>Stock Code:</strong>
            </Col>
            <Col sm={1}>{selectedBin?.stock_code}</Col>
            <Col sm={3}>
              <strong>Pick-Up Code:</strong>
            </Col>
            <Col sm={2}>{selectedBin?.pickup_code}</Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default VerifyOrder;
