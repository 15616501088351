/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from '../../components/bootstrap/Loader'
import Select from 'react-select'
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import request from '../../../services/AxiosInstance';
import { toast } from 'react-toastify';
import productStatusOpt from '../../../constant/ProductStatus';
import ProductCategoryOpt from '../../../constant/ProductCategory';

const AddProduct = ({ rowData, type, setType }) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [accountValues, setAccountValues] = useState([]);
    const [parentId, setParentId] = useState('')
    const [productName, setProductName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState('');
    const [brand, setBrand] = useState('');
    const [sku, setSku] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [productImg, setProductImg] = useState('');
    const [productStatus, setProductStatus] = useState('');
    const [productCategory, setProductCategory] = useState({ value: "669ddcbb-372e-4395-97f1-992ed44c5ec0", label: "Electronic" });
    const [productId, setProductId] = useState('');
    const [productNameError, setProductNameError] = useState('');
    const [productIdError, setProductIdError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');

    const handleReset = () => {
        setAccountValues([]);
        setParentId('');
        setProductName('');
        setPrice('');
        setDescription('');
        setQuantity('');
        setBrand('');
        setSku('');
        setWeight('');
        setHeight('');
        setWidth('');
        setLength('');
        setProductImg('');
        setProductStatus('');
        setProductCategory('');
    }

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        const fileWithPreview = Object.assign(file, {
            preview: URL.createObjectURL(file)
        });
        setProductImg(fileWithPreview)
    }, [])

    const handleRemoveImage = () => setProductImg(null);
    const { getRootProps } = useDropzone({ onDrop })

    const handleSubmit = async () => {
        let isValid = true;

        setProductNameError('');
        setProductIdError('');
        setDescriptionError('');

        if (!productName.trim()) {
            setProductNameError("Product Name is required.");
            isValid = false;
        }
        if (!productId.trim()) {
            setProductIdError("Product ID is required.");
            isValid = false;
        }
        if (!description.trim()) {
            setDescriptionError("Description is required.");
            isValid = false;
        }

        if (!isValid) return;

        setLoader(true);
        let data = new FormData();
        data.append('account_id', parentId && parentId.value);
        data.append('category_id', productCategory && productCategory.value);
        data.append('status', productStatus && productStatus.value);
        data.append('name', productName);
        data.append('price', price);
        data.append('qty', quantity);
        data.append('brand', brand);
        data.append('sku', sku);
        data.append('weight', weight);
        data.append('height', height);
        data.append('width', width);
        data.append('length', length)
        data.append('desc', description);
        data.append('file', productImg);
        data.append('product_id', productId)

        if (!['View', 'Edit'].includes(type)) {
            const result = fetch(process.env.REACT_APP_BASEURL + "apis/i-ecom/ecom_product", {
                method: "POST",
                withCredentials: true,
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken
                            .jwtToken,
                },
                body: data,
            });
            result
                .then((result) => {
                    result.json().then((res) => {
                        if (res.message) {
                            toast.error("Error while adding product.")
                            setLoader(false);
                            return;
                        } else {
                            handleReset();
                            setLoader(false);
                            navigate('/product-list');
                            toast.success("Product added successfully !!")
                        }
                    });
                })
                .catch((error) => {
                    setLoader(false)
                    toast.error(error.message)
                });
        } else {
            data.append('id', rowData[0].id)
            const result = fetch(process.env.REACT_APP_BASEURL + "apis/i-ecom/ecom_product", {
                method: "PUT",
                withCredentials: true,
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(sessionStorage.getItem("user")).signInUserSession.idToken
                            .jwtToken,
                },
                body: data,
            });
            result
                .then((result) => {
                    result.json().then((res) => {
                        if (res.message) {
                            toast.error("Error while updating product.")
                            setLoader(false);
                            return;
                        } else {
                            setType('');
                            handleReset();
                            toast.success("Product updated !!");
                        }
                    });
                })
                .catch((error) => {
                    setLoader(false)
                    toast.error(error.message)
                });
        }
    }

    const getAccounts = async () => {
        const accResData = await request('get', 'accounts');
        if (accResData.message) {
            toast.error(accResData.message)
            return;
        }
        const accountValues = accResData.map((account) => {
            return { value: account.id, label: account.name };
        });
        setAccountValues(accountValues)
        setParentId(accountValues[0])
    }

    useEffect(() => {
        getAccounts();
        if (rowData && rowData.length > 0) {
            let [data] = rowData
            setProductName(data.name);
            setPrice(data.price);
            // setQuantity(data.qty);
            // setBrand(data.brand);
            // setSku(data.sku);
            setWeight(data.weight);
            setHeight(data.height);
            setWidth(data.width);
            setLength(data.length)
            setDescription(data.desc);
            setProductImg(data.product_url);
            setProductId(data.product_id);

            const category = ProductCategoryOpt.find(option => option.value === data.category_id);
            if(![undefined,'undefined',null,'null',''].includes(category)){
                setProductCategory(category);
            }else{
                setProductCategory({ value: "669ddcbb-372e-4395-97f1-992ed44c5ec0", label: "Electronic" })
            }

            const status = productStatusOpt.find(option => option.value === data.status);
            setProductStatus(status)
        }
    }, [])

    return (
        <div className='position-relative'>
            {loader ? <div className="loader">
                <Loader />
            </div> : ""}

            <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                    <h3 className="my-3 fw-bold">{type === 'View' ? "Product" : type === 'Edit' ? "Update Product" : "Add Product"}</h3>
                </div>
                <div className='col-xl-10 col-lg-10'>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-4'>
                            <div className="form-group mb-3">
                                <label>Parent Account</label>
                                <Select
                                    value={parentId}
                                    onChange={(val) => setParentId(val)}
                                    options={accountValues}
                                    style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                    }}
                                    disabled={type === 'View'}
                                />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="form-group mb-3">
                                <label>Product Name</label>
                                <input
                                    type="text"
                                    value={productName}
                                    disabled={type === 'View'}
                                    onChange={(e) => setProductName(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                                {productNameError && <small className="text-danger">{productNameError}</small>}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="form-group mb-3">
                                <label>Product ID</label>
                                <input
                                    type="text"
                                    value={productId}
                                    disabled={type === 'View'}
                                    onChange={(e) => setProductId(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                                {productIdError && <small className="text-danger">{productIdError}</small>}
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8">
                            <div className="form-group mb-3">
                                <label>Description</label>
                                <textarea
                                    type="text"
                                    value={description}
                                    disabled={type === 'View'}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                                {descriptionError && <small className="text-danger">{descriptionError}</small>}
                            </div>
                        </div>
                        <div className='col-xl-12 col-lg-12'>
                            <h5 className="my-3 fw-bold">Attributes</h5>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <div className="form-group mb-3">
                                <label>Price</label>
                                <input
                                    type="text"
                                    value={price}
                                    disabled={type === 'View'}
                                    onChange={(e) => setPrice(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-2'>
                            <div className="form-group mb-3">
                                <label>Category</label>
                                <Select
                                    value={productCategory}
                                    onChange={(val) => setProductCategory(val)}
                                    options={ProductCategoryOpt}
                                    style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                    }}
                                    disabled={type === 'View'}
                                />
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <div className="form-group mb-3">
                                <label>Weight</label>
                                <input
                                    type="text"
                                    value={weight}
                                    disabled={type === 'View'}
                                    onChange={(e) => setWeight(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <div className="form-group mb-3">
                                <label>Height</label>
                                <input
                                    type="text"
                                    value={height}
                                    disabled={type === 'View'}
                                    onChange={(e) => setHeight(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <div className="form-group mb-3">
                                <label>Width</label>
                                <input
                                    type="text"
                                    value={width}
                                    disabled={type === 'View'}
                                    onChange={(e) => setWidth(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                            <div className="form-group mb-3">
                                <label>Length</label>
                                <input
                                    type="text"
                                    value={length}
                                    disabled={type === 'View'}
                                    onChange={(e) => setLength(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-2'>
                            <div className="form-group mb-3">
                                <label>Status</label>
                                <Select
                                    value={productStatus}
                                    onChange={(val) => setProductStatus(val)}
                                    options={productStatusOpt}
                                    style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                    }}
                                    disabled={type === 'View'}
                                />
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-lg-4">
                            <div className="form-group mb-3">
                                <label>Quantity</label>
                                <input
                                    type="text"
                                    value={quantity}
                                    disabled={type === 'View'}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                            </div>
                        </div> */}
                        {/* <div className="col-xl-4 col-lg-4">
                            <div className="form-group mb-3">
                                <label>Brand</label>
                                <input
                                    type="text"
                                    value={brand}
                                    disabled={type === 'View'}
                                    onChange={(e) => setBrand(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="form-group mb-3">
                                <label>SKU</label>
                                <input
                                    type="text"
                                    value={sku}
                                    disabled={type === 'View'}
                                    onChange={(e) => setSku(e.target.value)}
                                    className="form-control  form-control-sm fs-14"
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='col-xl-2 col-lg-2'>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div id='avatarImg' {...getRootProps()}>
                            {productImg ? (
                                <div className='bx-shd'>
                                    <img className="rounded-3" src={productImg.preview ? URL.createObjectURL(productImg) : productImg} height='120px' width='120px' alt="AccountImage" />
                                </div>
                            ) : (<div className='bx-shd'> <svg focusable="false" aria-hidden="true" width="100px" height="100px" viewBox="0 0 24 24" data-testid="CloudUploadIcon">
                                <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3" fill="green" />
                            </svg>
                            </div>)}
                        </div>
                        <label className='pt-1'>Upload Image</label>
                        <Button variant='info tp-btn btn-sm w-50 fs-12' onClick={() => handleRemoveImage('account')}>REMOVE IMAGE</Button>
                    </div>
                </div>
                <div className='col-xl-12 col-lg-12'>
                    <div className='col-12 text-end'>
                        <Button
                            onClick={() => ['View', 'Edit'].includes(type) ? setType('') : navigate('/product-list')}
                            className='me-2 btn-sm' variant='primary'>
                            CANCEL
                        </Button>
                        {type !== 'View' ?
                            <Button onClick={() => handleSubmit()} variant='primary btn-sm mx-2'>{type === 'Edit' ? "Update" : "Add"}</Button> : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProduct
